import image1 from "./standard/1.png";
import image2 from "./standard/2.png";
import image3 from "./standard/3.png";
import image4 from "./standard/4.png";
import image5 from "./standard/5.png";
import image6 from "./standard/6.png";
import image7 from "./standard/7.png";
import image8 from "./standard/8.png";
import image9 from "./standard/9.png";
import image10 from "./standard/10.png";
import image11 from "./standard/11.png";
import image12 from "./standard/12.png";
import image13 from "./standard/13.png";
import image14 from "./standard/14.png";
import image15 from "./standard/15.png";
import image16 from "./standard/16.png";
import image17 from "./standard/17.png";
import image18 from "./standard/18.png";
import image19 from "./standard/19.png";
import image20 from "./standard/20.png";
import image21 from "./standard/21.png";
import image22 from "./standard/22.png";
import image23 from "./standard/23.png";
import image24 from "./standard/24.png";
import image25 from "./standard/25.png";
import image26 from "./standard/26.png";
import image27 from "./standard/27.png";
import image28 from "./standard/28.png";
import image29 from "./standard/29.png";
import image30 from "./standard/30.png";
import image31 from "./standard/31.png";
import image32 from "./standard/32.png";
import image33 from "./standard/33.png";
import image34 from "./standard/34.png";
import image35 from "./standard/35.png";
import image36 from "./standard/36.png";
import image37 from "./standard/37.png";
import image38 from "./standard/38.png";
import image39 from "./standard/39.png";
import image40 from "./standard/40.png";
import image41 from "./standard/41.png";
import image42 from "./standard/42.png";
import image43 from "./standard/43.png";
import image44 from "./standard/44.png";
import image45 from "./standard/45.png";
import image46 from "./standard/46.png";
import image47 from "./standard/47.png";
import image48 from "./standard/48.png";
import image49 from "./standard/49.png";
import image50 from "./standard/50.png";
import image51 from "./standard/51.png";
import image52 from "./standard/52.png";
import image53 from "./standard/53.png";
import image54 from "./standard/54.png";
import image55 from "./standard/55.png";
import image56 from "./standard/56.png";

import rare1 from "./rare/1.png";
import rare2 from "./rare/2.png";
import rare3 from "./rare/3.png";
import rare4 from "./rare/4.png";
import rare5 from "./rare/5.png";

export const RareImagesAmount = 5;
export const RareImages = {
  rare1,
  rare2,
  rare3,
  rare4,
  rare5,
};

export const ImagesAmount = 56;
export const Images = {
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
  image32,
  image33,
  image34,
  image35,
  image36,
  image37,
  image38,
  image39,
  image40,
  image41,
  image42,
  image43,
  image44,
  image45,
  image46,
  image47,
  image48,
  image49,
  image50,
  image51,
  image52,
  image53,
  image54,
  image55,
  image56,
};
