/* eslint-disable */

import React, { useState, useEffect } from "react";
import Adj from "../assets/adjectives.json"; //When adding new names/adj must be at the bottom' else will break when generating an existing monster from dnaString
import Names from "../assets/names.json"; //When adding new names/adj must be at the bottom' else will break when generating an existing monster from dnaString
import { Images, RareImages } from "../assets/images/backgrounds";
import {
  generateCharacterFromDNAString,
  // returnArrayOfDNAForMinting, //this is used to populate the json files
  generateAFreshCharacter,
  monsterTypes,
  createImageAndUploadToIPFS,
} from "../utils/generateHelper";

//This is used for populating the nftData array to fill in nftDataPreMint.js
// import lowDNAStrings from "../assets/dnaStrings/series1/low.json";
// import midDNAStrings from "../assets/dnaStrings/series1/mid.json";
// import rareDNAStrings from "../assets/dnaStrings/series1/rare.json";
// import legendaryDNAStrings from "../assets/dnaStrings/series1/legendary.json";
// const theCollectionArray = [
//   ...lowDNAStrings[`lowDNAStrings`],
//   ...midDNAStrings[`midDNAStrings`],
//   ...rareDNAStrings[`rareDNAStrings`],
//   ...legendaryDNAStrings[`legendaryDNAStrings`],
// ];

const defaultState = {
  loading: true,
  RandNumForName: 0,
  RandNumForAdj: 0,
  RandAdj: "",
  RandName: "",
  rareNumber: 0,
  isItRare: false,
  bgImage: "",
  bgNumber: 0,
  monsterImage: "",
  monsterNumber: 0,
  monsterTypeNumber: 0,
  dnaString: {},
  strength: 0,
  stealth: 0,
  speed: 0,
  magic: 0,
  rarity: 0,
  avg: 0,
  isCardLegendary: 0,
};

const GenerateCharacterComponent = (props) => {
  const names = Names?.names || [];
  const adj = Adj?.words || [];

  const [state, updateState] = useState(defaultState);

  useEffect(() => {
    if (props.generateFromDNA) {
      generateCharacterFromDNAString(
        props.DNAString,
        updateState,
        Names,
        Adj,
        monsterTypes,
        RareImages,
        Images
      );
    } else {
      setTimeout(function () {
        generateAFreshCharacter(updateState, names, adj);
      }, 1000);

      // setTimeout(function () {
      //   generateAFreshCharacter(updateState, names, adj);
      //   This creates the charcters for the json files
      //   const Arr = returnArrayOfDNAForMinting(
      //     state,
      //     updateState,
      //     names,
      //     adj,
      //     "legendary",
      //     50
      //   );
      //   console.log(Arr);
      //   //
      //   //
      //   //This is for creating data for nftDataPreMint.js
      //   const nftData = [];
      //   // 1 Loop over all generated characters
      //   // 2 Create IPFS ImageURL, metadataURL, image hash & ipfs hash
      //   // 3 Push data one by one into the array
      //   // 4 log out contents of array
      //   // 5 put array into nftDataPreMint.js
      // const generateChar = async (dnaString) =>
      //   new Promise((resolve, reject) => {
      //     generateCharacterFromDNAString(
      //       dnaString,
      //       updateState,
      //       Names,
      //       Adj,
      //       monsterTypes,
      //       RareImages,
      //       Images
      //     );
      //     setTimeout(function () {
      //       //delay to allow dom to render character -- so we can save to ipfs
      //       resolve(true);
      //     }, 800);
      //   });
      // (async () => {
      //   for (let i = 0; i < theCollectionArray.length; i++) {
      //     const { dnaString, description, name } = theCollectionArray[i];
      //     await generateChar(dnaString);
      //     const ipfsData = await createImageAndUploadToIPFS(
      //       dnaString,
      //       description,
      //       name
      //     );
      //     const singleCharData = {
      //       name,
      //       description,
      //       dnaString,
      //       imageUrl: ipfsData?.uploadedImageUrl,
      //       metadataUrl: ipfsData?.metaDataUrl,
      //       ipfsImageHashToPin: ipfsData?.imageHashToPin,
      //       ipfsMetaHashToPin: ipfsData?.metaDataHashToPin,
      //       series: "1",
      //     };
      //     nftData.push(singleCharData);
      //   }
      //   console.log(JSON.stringify(nftData));
      // })();
      // }, 1000);
    }
  }, []);

  const isLegendary = state.isCardLegendary;

  return <p>404!</p>;

  return (
    <div className="generate">
      {state.loading && (
        <p className="card__loading">Creating Crypto Ghoul ... </p>
      )}

      {!state.loading && (
        <div
          className="card__container"
          onClick={() => createImageAndUploadToIPFS()}
        >
          {isLegendary && <p className={`card__legendary-title`}>Legendary</p>}
          {state.isItRare && !isLegendary && (
            <p className={`card__rare-title`}>Rare</p>
          )}
          {state.avg < 51 && <p className={`card__tier-title`}>Low tier</p>}
          {state.avg >= 51 && !state.isItRare && (
            <p className={`card__tier-title card__tier-title--white`}>
              Mid tier
            </p>
          )}
          <p
            className={`card__top-number ${
              state.isItRare && !isLegendary ? "card__top-number--rare" : ""
            } ${state.avg < 51 ? "card__top-number--bad" : ""} 
            ${isLegendary ? "card__top-number--legendary" : ""}
            `}
          >
            {state.avg}
          </p>
          <p
            className={`card__name  ${
              state.isItRare && !isLegendary ? "card__name--rare" : ""
            } ${isLegendary ? "card__name--legendary" : ""}
            ${state.avg < 51 ? "card__name--bad" : ""} `}
          >
            {state.RandAdj} {state.RandName}
          </p>
          <div
            className="card__monster"
            style={{ backgroundImage: `url(${state.monsterImage})` }}
          />
          <div
            className="card__monster-bg"
            style={{ backgroundImage: `url(${state.bgImage})` }}
          />
          <div className="card__stats">
            <div className="card__stats--left">
              <p>Strength :</p>
              <p>Stealth :</p>
              <p>Speed :</p>
              <p>Magic :</p>
              <p>Rarity :</p>
            </div>
            <div className="card__stats--right">
              <p> {state.strength} / 100</p>
              <p> {state.stealth} / 100</p>
              <p> {state.speed} / 100</p>
              <p> {state.magic} / 100</p>
              <p> {state.rarity} / 100</p>
            </div>
          </div>
        </div>
      )}
      {!props.generateFromDNA && (
        <>
          <p
            style={{ marginBottom: 30 }}
            onClick={() =>
              generateCharacterFromDNAString(
                "1-1-10-3-99-99-100-98-99-137-170-99-1",
                updateState,
                Names,
                Adj,
                monsterTypes,
                RareImages,
                Images
              )
            }
            className="generate__load"
          >
            Load from DNA string
          </p>

          <p
            style={{ marginTop: 0 }}
            onClick={() => generateAFreshCharacter(updateState, names, adj)}
            className="generate__load"
          >
            Create New Character
          </p>
        </>
      )}
    </div>
  );
};

export default GenerateCharacterComponent;
