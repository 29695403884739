import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppWrapper from "../src/components/app-wrapper";
import Home from "./pages/home";
import Generate from "./pages/generate";
import Account from "./pages/account";
import Purchase from "./pages/purchase";
import Gallery from "./pages/gallery";

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./store/reducers";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./store/sagas/index";

const sagaMiddleware = createSagaMiddleware();

const composer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers = composer(applyMiddleware(sagaMiddleware));
const store = createStore(reducers, enhancers);
sagaMiddleware.run(rootSaga);

export default function BasicExample() {
  return (
    <Provider store={store}>
      <Router>
        <AppWrapper>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/generate">
              <Generate />
            </Route>
            <Route path="/account">
              <Account />
            </Route>
            <Route path="/purchase">
              <Purchase />
            </Route>
            <Route path="/gallery">
              <Gallery />
            </Route>
            <Route path="*">{() => <p>404 Page</p>}</Route>
          </Switch>
        </AppWrapper>
      </Router>
    </Provider>
  );
}
