/* eslint-disable */
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
import EthIcon from "../assets/images/Eth-icon.png";
import { connect } from "react-redux";
import { getChain } from "evm-chains";
import MobileMenu from "./mobile-menu";
import { detectBrowser } from "../utils/detect-browser";
import Contract from "../truffle/abis/NFT.json";
import axios from "axios";

const networkMap = {
  1: "Mainnet",
  4: "Rinkeby",
  137: "Polygon-Mainnet",
  80001: "Polygon-Mumbai",
};

// const validNetwork = (netId) =>
//   netId === 1 || netId === 4 || netId === 137 || netId === 80001;
const validNetwork = (netId) => netId === 137; //for live only allow 137 -> polygon mainnet

const connectToWallet = async (showModal) => {
  if (detectBrowser() === "Other") {
    await window?.ethereum?.request({ method: "eth_requestAccounts" });
    window.location.reload(); //without the reload - it doesnt populate window.eth
  } else {
    window.location.assign("https://metamask.app.link/dapp/cryptoghoulz.com");
    // showModal({
    //   title: "Not Supported",
    //   text: `You are on an unsupported Browser. Please connect via the Meta Mask extension on Chrome / Firefox. If you are on a mobile device you can use the browser inside of the Meta Mask Mobile App.`,
    //   buttonText: "Close",
    //   type: "error",
    //   show: true,
    // });
  }
};

/**
 Try to create new user with the wallet address
 If it already exists , it fails graciously
 */
const createUserAnAccountIfNeeded = async (accountAddress) => {
  try {
    await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}v1/ghoulz/create-user`,
      method: "POST",
      headers: {
        authorization: process.env.REACT_APP_API_KEY,
      },
      data: {
        wallet_address: accountAddress,
      },
    });
  } catch (e) {
    console.log("swallow, failed to check / create account");
    return;
  }
};

/**
 This loads the contract from the blockchain (using the abi) and puts it into the redux store
 */
export const loadContract = async (setContract, web3, netId, showModal) => {
  try {
    const contract = new web3.eth.Contract(
      Contract.abi,
      Contract.networks[netId].address
    );
    setContract(contract);

    // const totalSupply = await contract.methods.totalSupply().call();
    // console.log("totalSupply (header)", totalSupply);
    // const chainId = await web3.eth.getChainId();
    // console.log("chain id", chainId);

    // >>>>>>>>  GET URI OF THE FILE FOR AN NFT BASED ON ITS INDEX
    // const uri = await contract.methods.tokenURI(999).call();
    // console.log("999", uri);
    // const uri2 = await contract.methods.tokenURI(1002).call();
    // console.log("1002", uri2);
    // const uri3 = await contract.methods.tokenURI(1003).call();
    // console.log("1002", uri3);

    // >>>>>>>>  GET OWNER OF NFT BASED ON ITS INDEX -> IF NOT PURCHASED THEN THE OWNER IS THE CONTRACT ADDRESS
    // const owner1 = await contract.methods.ownerOf(1).call();
    // console.log("owner1", owner1);
    // const owner2 = await contract.methods.ownerOf(2).call();
    // console.log("owner2", owner2);

    // >>>>>>>>  GET IF NFT HAS BEEN SOLD-> bool response
    // const sold = await contract.methods.sold(1).call();
    // console.log("sold", sold);

    // >>>>>>>>  GET NFT PRICE -> price is returned in Wei so 1000000000000000  === 0.001 ETH
    // const price = await contract.methods.price(999).call();
    // console.log("price", price);

    //Check user is on a Network we are supporting
    // console.log("netId ", netId);
    if (validNetwork(netId)) {
      if (window.location?.pathname !== "/gallery") {
        showModal({
          title: "Excellent",
          text: `You have successfully connected to Crypto Ghoulz on <strong>${networkMap[netId]}</strong>`,
          buttonText: "Close",
          type: "alert",
          show: true,
        });
      }
    } else {
      throw "invalid network";
    }

    return contract;
  } catch (e) {
    showModal({
      title: "Not Supported",
      text: `You are on an unsupported Network.<br/>Please connect to Polygon-Mainnet`,
      buttonText: "Close",
      type: "error",
      show: true,
    });
    // console.log("Error, load contract: ", e);
    setContract(null);
    return null;
  }
};

const initWeb3 = async (logIn, logOut, setContract, showModal) => {
  const web3Param =
    new URLSearchParams(window.location.search).get("noWeb3") || false;
  try {
    if (web3Param) {
      return;
    }

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {}, // required
    });

    let provider;
    // setTimeout(async () => {
    //   if (!provider) {
    //     return (
    //       window &&
    //       window.location.assign("https://cryptoghoulz.com/?noWeb3=true")
    //     );
    //   }
    // }, 1000);

    provider = await web3Modal.connect();
    const web3 = new Web3(provider);

    const netId = await web3.eth.net.getId(); // the network is determined by the meta mask wallet connection > ganache is 5777 | rinkeby is 4 |
    await loadContract(setContract, web3, netId, showModal);

    //
    //Settings only apply for MetaMask as MetaMask creates the window.ethereum object
    if (typeof window.ethereum !== "undefined") {
      let balance, web3;

      window.ethereum.autoRefreshOnNetworkChange = false;
      web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      const account = await accounts[0];
      if (typeof account !== "undefined") {
        balance = await web3.eth.getBalance(account);
        await createUserAnAccountIfNeeded(account);
        logIn({ balance, accountAddress: account, web3 });
      } else {
        logOut();
      }

      window.ethereum.on("accountsChanged", async (accounts) => {
        if (typeof accounts[0] === "undefined") {
          logOut();
        } else {
          balance = await web3.eth.getBalance(accounts[0]);
          await createUserAnAccountIfNeeded(accounts[0]);
          logIn({ balance, accountAddress: accounts[0] });
          window.location.reload(); //without the reload - it doesnt populate window.eth
        }
      });

      window.ethereum.on("chainChanged", async (chainId) => {
        //chainChanged === network change
        console.log("*chainChanged* account ", account);
        console.log("*chainChanged* chainId ", chainId);
        let network = "";
        try {
          network = await getChain(parseInt(chainId, 16));
          // console.log("network === ", network);
          window.location.reload(); //without the reload - it doesnt populate window.eth
        } catch (e) {
          console.log("network not found ", chainId);
        }

        if (account) {
          balance = await web3.eth.getBalance(account);
          await createUserAnAccountIfNeeded(account);
          logIn({ balance, accountAddress: account, network });
        }
      });
    }
  } catch (e) {
    console.log("swallow11 ", e);
  }
};

const Header = ({
  logIn,
  account,
  logOut,
  loggedIn,
  setContract,
  history,
  showModal,
}) => {
  const [showMenu, toggleMenu] = useState(false);

  useEffect(() => {
    initWeb3(logIn, logOut, setContract, showModal);
  }, []);

  return (
    <>
      <header className="wrapper__header">
        <h1 className="wrapper__header-logo" onClick={() => history.push("/")}>
          Crypto Ghoulz
        </h1>

        <div className="wrapper__header--right">
          <p className="wrapper__header-balance">
            {`Balance: ${account?.balance?.substring(0, 10) || 0}`}
            <br />
            Address: {account?.accountAddress?.substring(0, 15) || 0x0}...
            <br />
          </p>
          <div className="wrapper__header-eth-icon">
            <img
              className="wrapper__header-eth-icon-img"
              src={EthIcon}
              alt="ethereum icon"
            />
          </div>
          <div
            className="button button--margin-right button--wide"
            onClick={() => {
              if (loggedIn) {
                history.push("/account");
              } else {
                connectToWallet(showModal);
              }
            }}
          >
            <p>{loggedIn ? "My Account" : "Connect Wallet"}</p>
          </div>
        </div>

        <div
          className="wrapper__header--mobile"
          onClick={() => toggleMenu(!showMenu)}
        >
          <i className="fas fa-bars"></i>
        </div>
      </header>
      {showMenu && (
        <MobileMenu
          toggleMenu={toggleMenu}
          account={account}
          connectToWallet={connectToWallet}
          showModal={showModal}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logIn: (data) => dispatch({ type: "LOG_USER_IN", data }),
    setContract: (data) => dispatch({ type: "SET_CONTRACT", data }),
    logOut: () => dispatch({ type: "LOG_USER_OUT" }),
    showModal: (data) => dispatch({ type: "MODAL_SHOW", data }),
  };
};

const mapStateToProps = (state) => {
  const { account } = state;
  return { loggedIn: account.loggedIn, account: account.data };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
