/* eslint-disable */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import GenerateCard from "../components/generate-gallery-card";
const nftsFullMintedItemArr = require("../assets/dnaStrings/series1/nftDataPostMint.json");

//Filter out all duplicates from UI
const nftsData = nftsFullMintedItemArr["data"].filter(
  (item) => item.tokenId != 330 && item.tokenId != 331 && item.tokenId != 332
);

const theCollectionArray = nftsData;

const shuffledArray = theCollectionArray
  .sort(() => Math.random() - 0.5)
  .slice(1, 16);

const getOwnerStatus = (ghoul, contract, currentState, updateState) => {
  if (currentState.owner === "0x00" || !currentState.owner) {
    (async () => {
      try {
        if (contract) {
          const owner = await contract.methods.ownerOf(ghoul?.tokenId).call();
          const sold = await contract.methods.sold(ghoul?.tokenId).call();
          if (sold) {
            updateState({ ...currentState, sold: true, owner });
          }
          if (!sold) {
            updateState({ ...currentState, sold: false, owner });
          }
        }
      } catch (e) {
        console.log("swallow , failed to get owner");
      }
    })();
  }
};

const GalleryComponent = ({ contract }) => {
  const history = useHistory();

  const [currentState, updateState] = useState({
    showSingleCard: false,
    cardAvailable: true,
    dna: [],
    ghoul: {},
    sold: false,
    owner: "0x00",
  });

  useEffect(() => {
    window.scrollTo({
      top: 1,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="gallery">
      {/* 
          SHOW SELECTED CARD ONLY
      */}
      {currentState.showSingleCard && (
        <div className="gallery__single">
          <p
            onClick={() =>
              updateState({ showSingleCard: false, dna: "", ghoul: {} })
            }
            className="gallery__single-title gallery__single-title--close"
          >
            {"<"} Go Back
          </p>
          <p className="gallery__single-title">
            {currentState.ghoul?.RandAdj} {currentState.ghoul?.RandName}
          </p>
          <GenerateCard
            DNAString={currentState.dna}
            key={currentState.dna}
            galleryState={currentState}
            updateGalleryState={updateState}
          />

          {getOwnerStatus(
            currentState.ghoul,
            contract,
            currentState,
            updateState
          )}

          {currentState.sold && (
            <p className="gallery__single-title gallery__single-title--availability">
              This card is <span>Not Available 😢</span>
              <br />
              Owner: <sub>{currentState.owner}</sub>
            </p>
          )}

          {!currentState.sold && (
            <p className="gallery__single-title gallery__single-title--availability">
              This card is <span>Still Available 🚀</span>
              <br />
              {/* Owner: <sub>{currentState.owner}</sub> */}
            </p>
          )}
          <br />
          <p className="gallery__single-title gallery__single-title--availability">
            Feel lucky?
            <br />
            Go to the purchase screen and see what you can get! 🚀
          </p>
          <div
            className="home__information-button "
            style={{ marginTop: "20px" }}
            onClick={() => history.push("/purchase")}
          >
            <div
              className="button button--wide"
              style={{ backgroundColor: "black" }}
            >
              <p>Go to purchase</p>
            </div>
          </div>
        </div>
      )}

      {/* 
          SHOW SELECTION OF CARDS
      */}
      {!currentState.showSingleCard && (
        <>
          <div style={{ marginTop: "130px" }}>
            <p className="home__container-title">Gallery</p>
            <p
              className="home__container-subtitle"
              style={{ marginBottom: "10px" }}
            >
              A selection of ghoulz from series 1
            </p>
            <p
              className="home__container-subtitle"
              style={{ fontSize: "36px", marginTop: 0 }}
            >
              Select a card to see if it has an owner
            </p>
          </div>

          <div className="gallery__container">
            {shuffledArray.map((item) => (
              <GenerateCard
                DNAString={item.dnaString}
                key={item.dnaString}
                galleryState={currentState}
                updateGalleryState={updateState}
                tokenId={item.tokenId}
              />
            ))}

            <div
              className="home__information-button "
              onClick={() => window.location.reload()}
              style={{ width: "100%", marginTop: "100px" }}
            >
              <div
                className="button button--wide"
                style={{ backgroundColor: "black" }}
              >
                <p>Show More</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { account } = state;
  return {
    account: account.data,
    contract: account.contract,
  };
};

export default connect(mapStateToProps, null)(GalleryComponent);
