/* eslint-disable */

import React from "react";
import ReactHtmlParser from "react-html-parser";

import ModalAlert from "../assets/images/icons/modal-alert.png";
import ModalSuccess from "../assets/images/icons/modal-success.png";
import ModalError from "../assets/images/icons/modal-error.png";

const Modal = ({ data, hideModal }) => {
  const { title, text, buttonText, type, buttonAction = null } = data;
  return (
    <div className="modal__container">
      <div className="modal">
        {type != "loading" && <span onClick={() => hideModal()}>X</span>}
        <div
          className={`modal__image-container ${
            type === "loading" ? "modal__image-container-spin" : ""
          }`}
        >
          {type === "loading" && <img src={ModalAlert} alrt="icon" />}
          {type === "alert" && <img src={ModalAlert} alrt="icon" />}

          {type === "success" && <img src={ModalSuccess} alrt="icon" />}

          {type === "error" && <img src={ModalError} alrt="icon" />}
        </div>
        <h1>{title}</h1>
        <p>{ReactHtmlParser(text)}</p>

        {type != "loading" && (
          <div
            className="home__information-button "
            onClick={() => !buttonAction && hideModal()}
          >
            <div className="button button--wide modal__button">
              <p>{buttonText}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
