/* eslint-disable */

const { create } = require("ipfs-http-client");
const projectId = "1vgWYncVPyOxh2bYlSQ5OHLogwT";
const projectSecret = "725ccd81336fd6e9ac066d2bc70b51fc";
const auth =
  "Basic " + Buffer.from(projectId + ":" + projectSecret).toString("base64");

const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  path: "api/v0",
  // mode: "no-cors",
  // headers: {
  //   authorization: auth,
  // },
});

// const pinToIPFS = async (path) =>
//   fetch(`https://ipfs.infura.io:5001/api/v0/pin/add?arg=${path}`, {
//     method: "POST",
//     headers: {
//       Authorization: auth,
//     },
//     mode: "no-cors",
//   });

// const addToIPFS = async (body) =>
//   fetch(`https://ipfs.infura.io:5001/api/v0/add`, {
//     method: "POST",
//     headers: {
//       Authorization: auth,
//     },
//     mode: "no-cors",
//     body,
//   });

export const uploadFileToIPFS = async (
  file,
  name,
  description,
  uid,
  timestamp
) => {
  try {
    //1 ADD File
    const url = await client.add(file);
    // const url = await addToIPFS(file);                   //NO
    // const pinFileToIPFS = await pinToIPFS(url?.path);    //NO
    const uploadedImageUrl = `https://ipfs.infura.io/ipfs/${url?.path}`;

    //2 ADD Metadata
    const metadata = {
      name,
      description,
      image: uploadedImageUrl,
      uid,
      timestamp,
    };
    const metadataRes = await client.add(JSON.stringify(metadata));

    //3 RETURN ipfs data that will be placed in eth nftsData step 2 file
    const metaDataUrl = `https://ipfs.infura.io/ipfs/${metadataRes?.path}`;
    console.log("metaData Url >>>> ", metaDataUrl);
    console.log("image Url >>>> ", uploadedImageUrl);

    return {
      uploadedImageUrl,
      metaDataUrl,
      metaDataHashToPin: metadataRes?.path,
      imageHashToPin: url?.path,
    };
  } catch (e) {
    console.log("error uplaoding to IPFS", e);
  }
};
