/* eslint-disable */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Contract from "../truffle/abis/NFT.json";
import lowCardImg from "../assets/images/home/low.png";
import midCardImg from "../assets/images/home/mid.png";
import rareCardImg from "../assets/images/home/rare.png";
import legCardImg from "../assets/images/home/legendary.png";
import axios from "axios";

const getRandomUnsoldCardFromDB = async () => {
  const { data } = await axios({
    url: `${process.env.REACT_APP_API_BASE_URL}v1/ghoulz/get-available-token`,
    method: "GET",
    headers: {
      authorization: process.env.REACT_APP_API_KEY,
    },
    data: {},
  });
  return data;
};

const buyNFT = async (account, contract, showModal) => {
  const { accountAddress, web3 } = account;
  try {
    const netId = await web3.eth.net.getId();
    const cardToPurchase = await getRandomUnsoldCardFromDB();
    const tokenID = cardToPurchase[0]?.id_of_token;
    const dnaString = cardToPurchase[0]?.dnaString;

    // console.log("your are getting tokenID ", tokenID);

    if (!tokenID) {
      throw "no token id";
    }

    const id = tokenID;

    if (tokenID == "330" || tokenID == "331" || tokenID == "332") {
      throw "purchasing a duplicate, this should not have happened";
    }

    let price;
    if (netId === 4) {
      //rinkeby
      price = 21000000000000000; // 0.021 ETH - approx £50
    }
    if (netId === 137) {
      //polygon-mainnet
      price = 49000000000000000000; // 49 MATIC == approx £49
    }
    if (netId === 80001) {
      //polygon-testnet
      price = 100000000000000000; // 0.1 MATIC == approx £0.10
    }

    //Verify price is equal to amount set on block chain
    const priceOnChain = await contract.methods.price(id).call();
    const doesPriceOnChainMatchUI =
      parseInt(price, 10) === parseInt(priceOnChain, 10);

    if (!doesPriceOnChainMatchUI) {
      throw "price does not match price on chain";
    }

    showModal({
      title: "Purchase in progress",
      text: `Please do not refresh your browser. <br/>This can take between 30-60 seconds to complete.<br/><br/> To ensure a quick completion of this purchase you should <strong>increase your gas</strong>. `,
      buttonText: "Close",
      type: "loading",
      show: true,
    });

    await contract.methods
      .buy(id)
      .send({ from: accountAddress, value: price })
      .on("receipt", async (r) => {
        if (netId === 137) {
          try {
            //attempt to update token database with purchase information
            await axios({
              url: `${process.env.REACT_APP_API_BASE_URL}v1/ghoulz/update-token`,
              method: "PUT",
              headers: {
                authorization: process.env.REACT_APP_API_KEY,
              },
              data: {
                uuid: dnaString,
                id: tokenID,
                address: accountAddress,
                price: price,
              },
            });
            console.log("success updating server");
          } catch (e) {
            console.log("failed to update server");
          }
        }

        showModal({
          title: "Congratulations",
          text: `You've received the NFT with ID: ${id}.<br/>From Address: ${Contract.networks[netId].address}`,
          buttonText: "Close",
          type: "success",
          show: true,
        });
      })
      .on("error", (error) => {
        showModal({
          title: "Lost connection to your transaction",
          text: `This may be due to the purchase being cancelled or <strong>The gas was too low</strong> so the connection to your wallet has timed out.<br/><br/> Please check your wallet to follow the progress of the purchase.`,
          buttonText: "Close",
          type: "error",
          show: true,
        });
      });
  } catch (e) {
    showModal({
      title: "Lost connection to your transaction",
      text: `This may be due to the purchase being cancelled or <strong>The gas was too low</strong> so the connection to your wallet has timed out.<br/><br/> Please check your wallet to follow the progress of the purchase.`,
      buttonText: "Close",
      type: "error",
      show: true,
    });
    // console.log("Error buying token", e);
  }
};

const PurchaseComponent = ({ contract, account, showModal }) => {
  const [currentState, updateState] = useState({
    account: null,
    contract: null,
  });
  useEffect(() => {
    updateState({ account, contract });
    window.scrollTo({
      top: 1,
      left: 0,
      behavior: "smooth",
    });
  }, [account]);

  return (
    <div className="purchase">
      <div className="home__container">
        <p className="home__container-title">Purchase</p>
        <p className="home__container-subtitle">
          A unique 1 of 1 Crypto Ghoul NFT
        </p>
      </div>

      <div className="home__cards">
        <div className="home__card  home__card--rotate-left home__cards--1">
          <img src={legCardImg} alt="low monster example" />
        </div>
        <div className="home__card home__card--dip  home__cards--2">
          <img src={rareCardImg} alt="mid monster example" />
        </div>
        <div className="home__card  home__cards--3">
          <img src={lowCardImg} alt="rare monster example" />
        </div>
        <div className="home__card   home__cards--4">
          <img src={midCardImg} alt="legendary monster example" />
        </div>
      </div>

      <div className="home__information">
        <p className="home__information-title">What am i buying?</p>
        <p className="home__information-text">
          You are purchasing ownership of a <span>Unique</span>{" "}
          <span style={{ color: "hotpink" }}>Crypto Ghoul</span>{" "}
          <span>NFT</span>.
          <br />
          <br />
          We have minted a limited run of a <span>1000</span> unique cards for
          the series 1 collection.
          <br />
          <br />
          Card purchases are blind, you purchase without knowing what you are
          going to get.
          <br />
          <br />
          Each card in series 1 costs{" "}
          <span style={{ color: "hotpink" }}>49 MATIC</span>
          <br />
          <br />
        </p>
        {currentState.account && currentState.contract && (
          <div
            className="home__information-button "
            onClick={() => buyNFT(account, contract, showModal)}
          >
            <div className="button button--wide">
              <p>Buy now</p>
            </div>
          </div>
        )}

        {!currentState.account ||
          (!currentState.contract && (
            <p className="home__information-text">
              You need to connect to <strong>Polygon Mainnet</strong> to
              purchase
            </p>
          ))}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch({ type: "LOG_USER_OUT" }),
    showModal: (data) => dispatch({ type: "MODAL_SHOW", data }),
  };
};

const mapStateToProps = (state) => {
  const { account } = state;
  return {
    loggedIn: account.loggedIn,
    account: account.data,
    contract: account.contract,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseComponent);
