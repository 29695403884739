/* eslint-disable */

import { takeLatest } from "redux-saga/effects";

function* testSagaAsync() {
  try {
    console.log("IN SAGA 1");
  } catch (error) {
    console.log("IN SAGA error");
    console.log(error);
  }
}

export function* watchIncreaseCounter() {
  yield takeLatest("TEST_SAGA", testSagaAsync);
}
