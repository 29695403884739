/* eslint-disable */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import Web3 from "web3";

//TODO
// show the users tokens/Cards in the UI
// first need to get reconcilliation script running on server on a cron

const returnBalance = (balance = 0) => {
  return Web3.utils.fromWei(balance, "Ether").substring(0, 10);
};

const AccountComponent = ({ loggedIn, account }) => {
  useEffect(() => {
    window.scrollTo({
      top: 1,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  if (!loggedIn) {
    return (
      <div className="account">
        <p
          className="home__container-subtitle"
          style={{ marginTop: "170px", marginBottom: "10px", fontSize: "36px" }}
        >
          YOU NEED TO CONNECT YOUR WALLET
        </p>
      </div>
    );
  }

  return (
    <div className="account">
      <div style={{ marginTop: "130px" }}>
        <p className="home__container-title">Your Account</p>
      </div>

      <div className="account__section">
        <h2>Account Details</h2>
        <p>
          <strong>Balance:</strong> {returnBalance(account?.balance)}
          <br />
          <strong>Address:</strong> {account.accountAddress}
        </p>
      </div>

      <div className="account__section">
        <h2>Your Ghoulz</h2>
        <p>
          You can browse through your Ghoulz by viewing your account on Opensea{" "}
          <strong>
            <a
              href="https://opensea.io/account"
              target="_blank"
              rel="noreferrer"
            >
              HERE
            </a>
          </strong>
        </p>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch({ type: "LOG_USER_OUT" }),
  };
};

const mapStateToProps = (state) => {
  const { account } = state;
  return { loggedIn: account.loggedIn, account: account.data };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountComponent);
