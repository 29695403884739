import dino1 from "./dino/1.png";
import dino2 from "./dino/2.png";
import dino3 from "./dino/3.png";
import dino4 from "./dino/4.png";
import dino5 from "./dino/5.png";
import dino6 from "./dino/6.png";
import dino7 from "./dino/7.png";
import dino8 from "./dino/8.png";
import dino9 from "./dino/9.png";
import dino10 from "./dino/10.png";
import ghost1 from "./ghost/1.png";
import ghost2 from "./ghost/2.png";
import ghost3 from "./ghost/3.png";
import ghost4 from "./ghost/4.png";
import ghost5 from "./ghost/5.png";
import ghost6 from "./ghost/6.png";
import ghost7 from "./ghost/7.png";
import ghost8 from "./ghost/8.png";
import ghost9 from "./ghost/9.png";
import ghost10 from "./ghost/10.png";
import mummy1 from "./mummy/1.png";
import mummy2 from "./mummy/2.png";
import mummy3 from "./mummy/3.png";
import mummy4 from "./mummy/4.png";
import mummy5 from "./mummy/5.png";
import mummy6 from "./mummy/6.png";
import mummy7 from "./mummy/7.png";
import mummy8 from "./mummy/8.png";
import mummy9 from "./mummy/9.png";
import mummy10 from "./mummy/10.png";
import ninja1 from "./ninja/1.png";
import ninja2 from "./ninja/2.png";
import ninja3 from "./ninja/3.png";
import ninja4 from "./ninja/4.png";
import ninja5 from "./ninja/5.png";
import ninja6 from "./ninja/6.png";
import ninja7 from "./ninja/7.png";
import ninja8 from "./ninja/8.png";
import ninja9 from "./ninja/9.png";
import ninja10 from "./ninja/10.png";
import vampire1 from "./vampire/1.png";
import vampire2 from "./vampire/2.png";
import vampire3 from "./vampire/3.png";
import vampire4 from "./vampire/4.png";
import vampire5 from "./vampire/5.png";
import vampire6 from "./vampire/6.png";
import vampire7 from "./vampire/7.png";
import vampire8 from "./vampire/8.png";
import vampire9 from "./vampire/9.png";
import vampire10 from "./vampire/10.png";

export const Dino = {
  dino1,
  dino2,
  dino3,
  dino4,
  dino5,
  dino6,
  dino7,
  dino8,
  dino9,
  dino10,
};

export const Ghost = {
  ghost1,
  ghost2,
  ghost3,
  ghost4,
  ghost5,
  ghost6,
  ghost7,
  ghost8,
  ghost9,
  ghost10,
};

export const Mummy = {
  mummy1,
  mummy2,
  mummy3,
  mummy4,
  mummy5,
  mummy6,
  mummy7,
  mummy8,
  mummy9,
  mummy10,
};

export const Ninja = {
  ninja1,
  ninja2,
  ninja3,
  ninja4,
  ninja5,
  ninja6,
  ninja7,
  ninja8,
  ninja9,
  ninja10,
};

export const Vampire = {
  vampire1,
  vampire2,
  vampire3,
  vampire4,
  vampire5,
  vampire6,
  vampire7,
  vampire8,
  vampire9,
  vampire10,
};
