/* eslint-disable */

//This file has all of the methods which are used when generating monsters
import {
  Images,
  RareImages,
  RareImagesAmount,
  ImagesAmount,
} from "../assets/images/backgrounds";
import {
  Dino,
  Ghost,
  Mummy,
  Ninja,
  Vampire,
} from "../assets/images/characters";
import { uploadFileToIPFS } from "./ipfs";
import * as htmlToImage from "html-to-image";

var dataUriToBuffer = require("data-uri-to-buffer");

/*
This generates a PNG from the html turns it into a buffer and uploads to IPFS
Originally was an svg but this did not show in meta maskl mobile and rarible had trouble displaying svg correctly
*/
export const createImageAndUploadToIPFS = async (
  dnaString,
  description,
  name
) => {
  try {
    const data = await htmlToImage.toPng(
      document.getElementsByClassName("card__container")[0]
    );

    //buffer - works in all browsers and uploads to ipfs as a proper image
    let decoded = dataUriToBuffer(data);
    const uid = dnaString;
    const timestamp = Date.now();

    const ipfsData = await uploadFileToIPFS(
      decoded,
      name,
      description,
      uid,
      timestamp
    );
    /*
      var img = new Image();
      img.src = data;
      document.body.appendChild(img);
    */
    return ipfsData;
  } catch (e) {
    console.log("failed to upload to IPFS", e);
  }
};

/*

*/
export const purchaseNFT = async () => {
  try {
    // 1 A random charcter is selected from the pool of available characters
    // 2 user pays for NFT -> ownership is transfered
    // 3 the charcter pool is updated
    //    -> charcater is removed from available
  } catch (e) {
    alert("failed to complete purchase of NFT");
  }
};

/*
    This is the Array of all monsters 
*/
export const monsterTypes = [
  { type: Dino, name: "dino" },
  { type: Ghost, name: "ghost" },
  { type: Mummy, name: "mummy" },
  { type: Ninja, name: "ninja" },
  { type: Vampire, name: "vampire" },
];

/*
  This will generate a new character
  And decide what type is it is based on random scores
 */
export const generateAFreshCharacter = (
  updateState,
  names,
  adj,
  allowRerender = true
) => {
  const RandNumForName = Math.floor(Math.random() * names.length);
  const RandNumForAdj = Math.floor(Math.random() * adj.length);
  const RandName = names[RandNumForName];
  const RandAdj = adj[RandNumForAdj];

  //1 Is user getting a rare BG?  if === 0 its rare  ... 1/10 chance of being rare
  const rareNumber = Math.floor(Math.random() * 10);
  const isItRare = !!(rareNumber < 1);

  //2 -> BG image -> random number respectable to amount of items available
  let bgImage;
  let bgNumber;
  if (isItRare) {
    bgNumber = Math.floor(Math.random() * RareImagesAmount) + 1;
    bgImage = `${RareImages[`rare${bgNumber}`]}`;
  } else {
    bgNumber = Math.floor(Math.random() * ImagesAmount) + 1;
    if (bgNumber == ImagesAmount) {
      bgNumber = 1;
    }
    bgImage = `${Images[`image${bgNumber}`]}`;
  }

  //3 -> Monster Image -> if its rare they get the /type/10.png image
  let monsterImage;
  let monsterNumber;
  const monsterTypeNumber = Math.floor(Math.random() * monsterTypes.length);
  const monsterType = monsterTypes[monsterTypeNumber];

  if (isItRare) {
    monsterImage = `${monsterType.type[`${monsterType.name.toLowerCase()}10`]}`;
  } else {
    monsterNumber = Math.floor(Math.random() * 9) + 1;
    monsterImage = `${
      monsterType.type[`${monsterType.name.toLowerCase()}${monsterNumber}`]
    }`;
  }

  let strength = isItRare
    ? generateScore(100, isItRare)
    : generateScore(79, isItRare);
  let stealth = isItRare
    ? generateScore(100, isItRare)
    : generateScore(79, isItRare);
  const speed = isItRare
    ? generateScore(100, isItRare)
    : generateScore(79, isItRare);
  const magic = isItRare
    ? generateScore(100, isItRare)
    : generateScore(79, isItRare);
  let rarity = isItRare
    ? generateScore(100, isItRare)
    : generateScore(79, isItRare);

  const allScores = [strength, stealth, speed, magic, rarity];
  const sum = allScores.reduce((a, b) => a + b, 0);
  let avg = Math.ceil(sum / allScores.length || 0);
  avg = isItRare ? (avg < 79 ? avg + 17 : avg) : avg < 40 ? avg + 20 : avg; //61 is lowest a rare card can be

  //lenegdary -> //to simulate legendary set avg to be 89 and isItRare to true
  avg = avg === 89 && isItRare ? 99 : avg;
  const isLegendary = avg === 99;
  if (isLegendary) {
    //adjust scores
    strength = 99;
    stealth = 99;
    rarity = 99;
    const allScoresAmended = [99, 99, speed, magic, 99];
    const sumForLegendary = allScoresAmended.reduce((a, b) => a + b, 0);
    const score = Math.ceil(sumForLegendary / allScoresAmended.length || 0);
    avg = score <= 89 ? score + 10 : score; // make sure avg is always over 89 for Legendarys
  }

  const isCardLegendary = isLegendary ? 1 : 0; //this was created as we used to always give them an avg score of 99, but now its generated

  const dnaString = `${isItRare ? 1 : 0}-${monsterTypeNumber}-${
    isItRare ? 10 : monsterNumber
  }-${bgNumber}-${strength}-${stealth}-${speed}-${magic}-${rarity}-${RandNumForName}-${RandNumForAdj}-${avg}-${isCardLegendary}`;

  if (allowRerender) {
    updateState({
      loading: false,
      RandNumForName,
      RandNumForAdj,
      RandAdj,
      RandName,
      rareNumber,
      isItRare,
      bgImage,
      bgNumber,
      monsterImage,
      monsterNumber,
      monsterTypeNumber,
      dnaString,
      strength,
      stealth,
      speed,
      magic,
      rarity,
      avg,
      isCardLegendary,
    });
  }

  return {
    dnaString,
    isItRare,
    avg,
    isLegendary,
    RandAdj,
    RandName,
    isCardLegendary,
  };
};

/*
  Based on the type & numberToGenerate passed into this function
  It will generate a specific set of characters of the specific type.
  We need this when generating the charcters for minting
  Once generated copy teh output from console into one of the json files
*/
export const returnArrayOfDNAForMinting = (
  state,
  updateState,
  names,
  adj,
  type,
  numberToGenerate
) => {
  const Arr = [];

  let predicate;
  if (type === "low") {
    console.log("generating LOW");
    predicate = (avg, isItRare, isLegendary) => avg < 51;
  } else if (type === "mid") {
    console.log("generating MID");
    predicate = (avg, isItRare, isLegendary) => avg >= 51 && !isItRare;
  } else if (type === "rare") {
    console.log("generating RARE");
    predicate = (avg, isItRare, isLegendary) => isItRare && !isLegendary;
  } else if (type === "legendary") {
    console.log("generating LEGENDARY");
    predicate = (avg, isItRare, isLegendary) => isItRare && !!isLegendary;
  }

  let numberGenerated = 0;
  for (let i = 0; i < 100000; i++) {
    const character = generateAFreshCharacter(updateState, names, adj, false);
    if (
      predicate(character.avg, character.isItRare, character.isCardLegendary)
    ) {
      Arr.push({
        dnaString: character?.dnaString,
        description: `number ${
          numberGenerated + 1
        } of the series 1 ${type} collection`,
        name: `${character.RandAdj} ${character.RandName}`,
      });
      numberGenerated++;
    }

    if (numberGenerated == numberToGenerate) {
      break;
    }
  }

  return JSON.stringify(Arr);
};

/*
This generates the score for attributes of the card
*/
export const generateScore = (max, isCardRare) => {
  let score;
  const thresholdScore = isCardRare ? 70 : 21;
  const additionalScore = isCardRare ? 29 : 45;

  score = Math.floor(Math.random() * max) + 1;
  score = score < thresholdScore ? score + additionalScore : score;
  return score;
};

/*
This method generates a monster from a given 'uid' aka a 'dnaString'
*/
export const generateCharacterFromDNAString = (
  dna,
  updateState,
  Names,
  Adj,
  monsterTypes,
  RareImages,
  Images,
  tokenId = ""
) => {
  const names = Names?.names || [];
  const adj = Adj?.words || [];
  const dnaArray = dna.split("-");
  const isItRare = dnaArray[0] == 1 ? true : false;
  const monsterTypeNumber = dnaArray[1];
  const monsterNumber = dnaArray[2];
  const bgNumber = dnaArray[3];
  const strength = dnaArray[4];
  const stealth = dnaArray[5];
  const speed = dnaArray[6];
  const magic = dnaArray[7];
  const rarity = dnaArray[8];
  const RandNumForName = dnaArray[9];
  const RandNumForAdj = dnaArray[10];
  const avg = dnaArray[11];
  const isCardLegendary = dnaArray[12] == 1 ? true : false;
  const RandName = names[RandNumForName];
  const RandAdj = adj[RandNumForAdj];
  const monsterType = monsterTypes[monsterTypeNumber];
  let monsterImage;

  if (isItRare) {
    monsterImage = `${monsterType.type[`${monsterType.name.toLowerCase()}10`]}`;
  } else {
    monsterImage = `${
      monsterType.type[`${monsterType.name.toLowerCase()}${monsterNumber}`]
    }`;
  }

  let bgImage;
  if (isItRare) {
    bgImage = `${RareImages[`rare${bgNumber}`]}`;
  } else {
    bgImage = `${Images[`image${bgNumber}`]}`;
  }

  updateState({
    loading: false,
    RandNumForName,
    RandNumForAdj,
    RandAdj,
    RandName,
    bgImage,
    monsterImage,
    dnaString: dna,
    isItRare,
    bgNumber,
    monsterNumber,
    monsterTypeNumber,
    strength,
    stealth,
    speed,
    magic,
    rarity,
    avg,
    isCardLegendary,
    tokenId,
  });
};
