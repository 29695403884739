/* eslint-disable */

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import YouTube from "react-youtube";
import lowCardImg from "../assets/images/home/low.png";
import midCardImg from "../assets/images/home/mid.png";
import rareCardImg from "../assets/images/home/rare.png";
import legCardImg from "../assets/images/home/legendary.png";

const YTOptions = {
  height: "390px",
  width: "100%",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0,
  },
};

const Home = () => {
  const history = useHistory();

  // UNCOMMENT
  useEffect(() => {
    window.scrollTo({
      top: 1,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="home">
      <div className="home__container">
        <p className="home__container-title">Crypto Ghoulz</p>
        <p className="home__container-subtitle">
          Click & Collect or Learn & Create
        </p>
      </div>

      <div className="home__cards">
        <div className="home__card  home__card--rotate-left home__cards--1">
          <img src={lowCardImg} alt="low monster example" />
        </div>
        <div className="home__card home__card--dip  home__cards--2">
          <img src={midCardImg} alt="mid monster example" />
        </div>
        <div className="home__card  home__cards--3">
          <img src={rareCardImg} alt="rare monster example" />
        </div>
        <div className="home__card   home__cards--4">
          <img src={legCardImg} alt="legendary monster example" />
        </div>
      </div>

      <div
        className="home__information-button home__information-button--top "
        onClick={() => history.push("/purchase")}
      >
        <div
          className="button button--wide"
          style={{ backgroundColor: "black" }}
        >
          <p>Purchase Now</p>
        </div>
      </div>

      {/* 
          *****************
          VIDEO
          *****************
      */}
      <div
        className="home__container"
        style={{ marginTop: "80px", marginBottom: "20px" }}
      >
        <p className="home__container-title">WATCH 🍿</p>
        <p className="home__container-subtitle">Set up your own project</p>
        <p className="home__container-info">
          This video walks you through the boilerplate and explains how it all
          works.
        </p>
        <div className="home__container-yt">
          <YouTube videoId="F0xD1DK3pe4" opts={YTOptions} />
        </div>
      </div>

      {/* 
          *****************
          BLOG POSTS
          *****************
      */}
      <div
        className="home__container"
        style={{ marginTop: "80px", marginBottom: "20px" }}
      >
        <p className="home__container-title">READ 📚</p>
        <p className="home__container-subtitle">
          Blog Posts explaining how it works
        </p>

        <div style={{ marginTop: "70px" }}>
          <p
            className="home__container-info home__container-link"
            onClick={() =>
              window.open(
                "https://medium.com/coinmonks/guide-to-creating-your-own-nft-with-javascript-solidity-part-1-of-3-7909b80fae94?source=your_stories_page-------------------------------------",
                "_blank"
              )
            }
          >
            🚀 Creating your own NFT with Javascript & Solidity - 1 of 3
          </p>
          <p
            className="home__container-info home__container-link"
            onClick={() =>
              window.open(
                "https://medium.com/coinmonks/guide-to-creating-your-own-nft-with-javascript-solidity-part-2-of-3-ceaa1cb2412a?source=your_stories_page-------------------------------------",
                "_blank"
              )
            }
          >
            👽 Creating your own NFT with Javascript & Solidity - 2 of 3
          </p>
          <p
            className="home__container-info home__container-link"
            onClick={() =>
              window.open(
                "https://medium.com/coinmonks/guide-to-creating-your-own-nft-with-javascript-solidity-part-3-of-3-6dae8da7e3f?source=your_stories_page-------------------------------------",
                "_blank"
              )
            }
          >
            🌎 Creating your own NFT with Javascript & Solidity - 3 of 3
          </p>
        </div>
      </div>

      {/* 
          *****************
          BOILERPLATE
          *****************
      */}
      <div
        className="home__container"
        style={{ marginTop: "80px", marginBottom: "20px" }}
      >
        <p className="home__container-title">Boilerplate 👨‍💻</p>
        <p className="home__container-subtitle">Make your own NFTs</p>
        <p className="home__container-info">
          This free boilerplate , allows you to quickly get started with your
          NFT project.
        </p>
        <p
          className="home__container-info home__container-link"
          style={{ marginTop: "100px" }}
          onClick={() =>
            window.open(
              "https://github.com/gary-george/nft-react-boilerplate",
              "_blank"
            )
          }
        >
          🖥 Free access to the boilerplate repo.
        </p>
      </div>

      <div className="home__information">
        <p className="home__information-title">What is a Crypto Ghoul?</p>
        <p className="home__information-text">
          A Crypto Ghoul is a uniquely generated NFT trading card, it cannot be
          replicated or taken away. Series 1 consists of 1000 cards ranging
          between 4 Tiers.
          <br />
          <br />
          <span style={{ color: "red" }}>Low</span>, <span>Mid</span>,{" "}
          <span style={{ color: "gold" }}>Rare</span> &{" "}
          <span style={{ color: "hotpink" }}>Legendary</span>.
          <br />
          <br />
          Will you get a <span style={{ color: "hotpink" }}>Legendary</span> or
          be stuck with a <span style={{ color: "red" }}>low</span> tier? 🚀
          <br />
          <br />
          <br />
          <span
            sx={{
              fontWeight: "600",
              fontSize: "28px",
              lineHeight: "20px !important",
            }}
          >
            "This project was set up, to help other developers get started with
            creating NFT contracts / Blockchain development."
          </span>
        </p>
        <div
          className="home__information-button "
          onClick={() => history.push("/purchase")}
        >
          <div className="button button--wide">
            <p>Purchase Now</p>
          </div>
        </div>
      </div>

      <div className="home__information home__steps">
        <p className="home__information-title">Gallery</p>
        <p className="home__information-text">
          See a vairiety of cards from the series 1 collection 🔥
          <br />
          Select a card and see if it is available.
          <br />
          <br />
          All cards are purchased blindly, luck decides the rarity of your
          purchase. 👻
          <br />
          <br />
        </p>
        <div
          className="home__information-button "
          onClick={() => history.push("/gallery")}
        >
          <div className="button button--wide">
            <p>Visit Gallery</p>
          </div>
        </div>
      </div>

      <div className="home__information" style={{ marginTop: 0 }}>
        <p className="home__information-title">Your Account</p>
        <p className="home__information-text">
          View your account information 👀
        </p>
        <div
          className="home__information-button "
          onClick={() => history.push("/account")}
        >
          <div className="button button--wide">
            <p>Your Account</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
