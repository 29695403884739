import React from "react";

const Footer = () => (
  <footer className="wrapper__footer">
    <div className="wrapper__footer-container">
      <h2>GET IN TOUCH</h2>
      <p>
        <i className="fab fa-twitter"></i>
        <a
          href="https://twitter.com/Crypto_Ghoulz"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
      </p>
      <p>
        <i className="fas fa-ship"></i>
        <a
          href="https://opensea.io/assets/crypto-ghoulz"
          target="_blank"
          rel="noreferrer"
        >
          Opensea
        </a>
      </p>
      <p>
        <i className="fas fa-envelope"></i>
        <a href="mailto:info@cryptoghoulz.com" target="_blank" rel="noreferrer">
          info@cryptoghoulz.com
        </a>
      </p>
    </div>
  </footer>
);

export default Footer;
