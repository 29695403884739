const INITIAL_STATE = {
  loggedIn: false,
  modal: {
    show: false,
    type: "success", //success || error || alert
    title: "TEST TITLE",
    text: " tesxt",
    buttonText: "Close",
    buttonAction: null, //this will be a key which will trigger a specific function, default null
  },
  data: {
    accountAddress: "",
    balance: "0",
    network: {},
    web3: {},
  },
};

export const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CONTRACT":
      return {
        ...state,
        contract: action.data,
      };
    case "LOG_USER_IN":
      return {
        ...state,
        loggedIn: true,
        data: action.data,
      };
    case "LOG_USER_OUT":
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case "MODAL_HIDE":
      return {
        ...state,
        modal: {
          show: false,
          type: "",
          title: "",
          text: "",
          buttonText: "",
          buttonAction: null,
        },
      };
    case "MODAL_SHOW":
      return {
        ...state,
        modal: action.data,
      };
    default:
      return state;
  }
};
