/* eslint-disable */

import React, { useState, useEffect } from "react";
import Adj from "../assets/adjectives.json"; //When adding new names/adj must be at the bottom' else will break when generating an existing monster from dnaString
import Names from "../assets/names.json"; //When adding new names/adj must be at the bottom' else will break when generating an existing monster from dnaString
import { Images, RareImages } from "../assets/images/backgrounds";
import {
  generateCharacterFromDNAString,
  monsterTypes,
} from "../utils/generateHelper";

const defaultState = {
  loading: true,
  RandNumForName: 0,
  RandNumForAdj: 0,
  RandAdj: "",
  RandName: "",
  rareNumber: 0,
  isItRare: false,
  bgImage: "",
  bgNumber: 0,
  monsterImage: "",
  monsterNumber: 0,
  monsterTypeNumber: 0,
  dnaString: "",
  strength: 0,
  stealth: 0,
  speed: 0,
  magic: 0,
  rarity: 0,
  avg: 0,
  isCardLegendary: 0,
  tokenId: 0,
};

const GenerateGalleryCharacterComponent = ({
  DNAString,
  galleryState,
  updateGalleryState,
  tokenId,
}) => {
  const [state, updateState] = useState(defaultState);

  useEffect(() => {
    generateCharacterFromDNAString(
      DNAString,
      updateState,
      Names,
      Adj,
      monsterTypes,
      RareImages,
      Images,
      tokenId
    );
  }, []);

  const isLegendary = state.isCardLegendary;
  return (
    <div style={{ width: "300px", margin: "0 auto" }}>
      {state.loading && (
        <p className="card-gallery__loading">Loading ghoul ... </p>
      )}

      {!state.loading && (
        <div
          className="card-gallery__container"
          style={{ cursor: "pointer" }}
          onClick={() => {
            updateGalleryState({
              showSingleCard: true,
              dna: `${DNAString}`,
              ghoul: { ...state, tokenId },
              owner: "0x00",
              sold: false,
            });
            window.scrollTo(0, 0);
          }}
        >
          {isLegendary && (
            <p className={`card-gallery__legendary-title`}>Legendary</p>
          )}
          {state.isItRare && !isLegendary && (
            <p className={`card-gallery__rare-title`}>Rare</p>
          )}
          {state.avg < 51 && (
            <p className={`card-gallery__tier-title`}>Low tier</p>
          )}
          {state.avg >= 51 && !state.isItRare && (
            <p
              className={`card-gallery__tier-title card-gallery__tier-title--white`}
            >
              Mid tier
            </p>
          )}
          <p
            className={`card-gallery__top-number ${
              state.isItRare && !isLegendary
                ? "card-gallery__top-number--rare"
                : ""
            } ${state.avg < 51 ? "card-gallery__top-number--bad" : ""} 
            ${isLegendary ? "card-gallery__top-number--legendary" : ""}
            `}
          >
            {state.avg}
          </p>
          <p
            className={`card-gallery__name  ${
              state.isItRare && !isLegendary ? "card-gallery__name--rare" : ""
            } ${isLegendary ? "card-gallery__name--legendary" : ""}
            ${state.avg < 51 ? "card-gallery__name--bad" : ""} `}
          >
            {state.RandAdj} {state.RandName}
          </p>
          <div
            className="card-gallery__monster"
            style={{ backgroundImage: `url(${state.monsterImage})` }}
          />
          <div
            className="card-gallery__monster-bg"
            style={{ backgroundImage: `url(${state.bgImage})` }}
          />
          <div className="card-gallery__stats">
            <div className="card-gallery__stats--left">
              <p>Strength :</p>
              <p>Stealth :</p>
              <p>Speed :</p>
              <p>Magic :</p>
              <p>Rarity :</p>
            </div>
            <div className="card-gallery__stats--right">
              <p> {state.strength} / 100</p>
              <p> {state.stealth} / 100</p>
              <p> {state.speed} / 100</p>
              <p> {state.magic} / 100</p>
              <p> {state.rarity} / 100</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateGalleryCharacterComponent;
