import React from "react";
import { connect } from "react-redux";
import Header from "./header";
import Footer from "./footer";
import { useHistory } from "react-router-dom";
import Modal from "./modal";

const AppWrapper = ({ children, modal, hideModal }) => {
  const history = useHistory();

  return (
    <div className="wrapper">
      {modal.show && <Modal data={modal} hideModal={hideModal} />}
      <Header history={history} />

      {children}

      <Footer />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideModal: () => dispatch({ type: "MODAL_HIDE" }),
  };
};

const mapStateToProps = (state) => {
  const { account } = state;
  return {
    modal: account.modal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
