import React from "react";
import { useHistory } from "react-router-dom";

const MobileMenu = ({ toggleMenu, account, connectToWallet, showModal }) => {
  const history = useHistory();

  return (
    <div className="mobile-menu">
      <div className="mobile-menu__menu">
        <div className="mobile-menu__close" onClick={() => toggleMenu(false)}>
          <i class="fas fa-times"></i>
        </div>

        <div className="mobile-menu__item-container">
          {account.accountAddress && (
            <p className="mobile-menu__item">
              Address:
              <br />
              <span>{account.accountAddress?.substring(0, 21) || 0x0}...</span>
            </p>
          )}
          {account.accountAddress && (
            <p className="mobile-menu__item">
              Balance:
              <br />
              <span>{account.balance?.substring(0, 21) || 0}</span>
            </p>
          )}

          {!account.accountAddress && (
            <p
              className="mobile-menu__item"
              onClick={() => {
                connectToWallet(showModal);
              }}
            >
              Connect
            </p>
          )}

          <p
            className="mobile-menu__item mobile-menu__item--mt"
            onClick={() => {
              toggleMenu(false);
              history.push("/");
            }}
          >
            Home
          </p>
          <p
            className="mobile-menu__item "
            onClick={() => {
              toggleMenu(false);
              history.push("/purchase");
            }}
          >
            Purchase
          </p>

          <p
            className="mobile-menu__item"
            onClick={() => {
              toggleMenu(false);
              history.push("/account");
            }}
          >
            Your Account
          </p>
          <p
            className="mobile-menu__item"
            onClick={() => {
              toggleMenu(false);
              history.push("/gallery");
            }}
          >
            Gallery
          </p>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
